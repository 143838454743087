import React from 'react';
import SEO from '../components/SEO';
import Layout from '../layouts/index';

const ThanksEn = () => {
	return (
		<Layout bodyClass="page-teams">
			<SEO title="Thanks" />
			<div className="container pt-4 pt-md-10 mb-10">
				<div className="row justify-content-start">
					<div className="col-12 col-md-8">
						<div className="service service-single">
							<h1 className="title">Thanks!</h1>
							<p>
								Thanks for contacting us. One of the team will be in touch soon.
							</p>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ThanksEn;
